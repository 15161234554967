export const ERRORS_VALIDATIONS_CONSTS = {
    LOGIN: {
        ERROR: {
            USER_NO_FOUND: 'Usuario no encontrado!',
            USER_OR_PASSWORD_INVALID: 'Usuario o Contraseña son Incorrectos'
        },
        VALIDATIONS: {
            USERNAME_REQUIRED: '* El Usuario es requerido',
            USERNAME_NO_PATTERN: '* El Usuario es invalido',
            PASSWORD_REQUIRED: '* La Contraseña es requerida',
            PASSWORD_MAX_LENGTH: '* La Contraseña debe tener maximo 10 caracteres',
        },
        ACCESS_RULES: {
            USER_UNASSIGNED_A_COMPANY: 'UNAUTHORIZED_USER',
            USER_INACTIVE_IN_COMPANY: 'USER_INACTIVE_IN_COMPANY',
        }
    },
    FORGOT_PASSWORD: {
        VALIDATIONS: {
            EMAIL_REQUIRED: '* El Correo Electrónico es requerido',
            EMAIL_NO_PATTERN: '* El Correo Electrónico es invalido',
        }
    },
    UPDATE_PASSWORD: {
        VALIDATIONS: {
            CURRENT_PASSWORD_REQUIRED: '* La contraseña actual es requerida',
            NEW_PASSWORD_REQUIRED: '* La nueva contraseña es requerida',
            CONFIRM_NEW_PASSWORD_REQUIRED: '* Debe confirmar la nueva contraseña',
            CONFIRM_NOT_MATCH_REQUIRED: '* La nueva Contraseña y la confirmación no coinciden',
            CURRENT_AND_NEW_PASS_NOT_SAME_REQUIRED: '* La Nueva Contraseña no puede ser igual a la Contraseña actual',
        }
    },
    COMPANY: {
        VALIDATIONS: {
            COMPANY_NAME_REQUIRED: '* El nombre de empresa es requerido',
            LEGAL_NAME_REQUIRED: '* Nombre Legal de la empresa es requerido',
            NIT_REQUIRED: '* NIT es requerido',
            ADDRESS_REQUIRED: '* La Dirección es requerida',
            DESC_REQUIRED: '* La Descripción es requerida',
        },
        ERROR: {
            CREATED_ERROR: 'Error creando la empresa',
            ASSIGNED_MODULE_ERROR: 'Error asignando el modulo a la empresa',
            COMPANY_UPDATE_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar la Empresa. Revise la información ingresada e intentelo nuevamente',
            COMPANY_UPDATE_STATUS_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar el estado de la Empresa. Revise la información ingresada e intentelo nuevamente',
        },
    },
    MODULE: {
        VALIDATIONS: {
            MODULE_NAME_REQUIRED: '* Nombre del modulo es requerido',
            MODULE_DESC_REQUIRED: '* Descripción del modulo es requerido',
            SUBMODULE_PATH_REQUIRED: '* Path del submodulo es requerido',
        },
        ERROR: {
            CREATED_ERROR: 'Ocurrio un error al momento de crear el modulo',
            UPDATE_ERROR: 'Ocurrio un error al momento de actualizar el Modulo. Revise la información ingresada e intentelo nuevamente',
            ASSIGNED_ERROR: 'Ocurrio un error al momento de asignar el modulo a la compañia',
            ASSIGNED_SUBMODULES_ERROR: 'Ocurrio un error al momento de asignar los submodulos a la compañia',
        }
    },
    ROLE: {
        VALIDATIONS: {
            ROLE_NAME_REQUIRED: '* Nombre del rol es requerido',
            ROLE_DESC_REQUIRED: '* Descripción del rol es requerido',
        },
        ERROR: {
            CREATED_ERROR: 'Ocurrio un error al momento de crear el Rol',
            UPDATE_ERROR: 'Ocurrio un error al momento de actualizar el Rol. Revise la información ingresada e intentelo nuevamente',
            ASSIGNED_ERROR: 'Ocurrio un error al momento de asignar el Rol',
        }
    },
    SUPERADMIN: {
        ERROR: {

        },
        VALIDATIONS: {
            FIRSTNAME_REQUIRED: '* Los Nombres son requeridos',
            LASTNAME_REQUIRED: '* Los Apellidos son requeridos',
            USERNAME_REQUIRED: '* El Usuario es requerido',
            USERNAME_NO_PATTERN: '* El Usuario es invalido',
            PASSWORD_REQUIRED: '* La Contraseña es requerida',
            PASSWORD_MAX_LENGTH: '* La Contraseña debe tener maximo 10 caracteres',
            EMAIL_REQUIRED: '* El Correo Electrónico es requerido',
            EMAIL_NO_PATTERN: '* El Correo Electrónico es invalido',
            DOCUMENT_REQUIRED: '* Número de Documento es requerido',
            DOCUMENT_TYPE_REQUIRED: '* El tipo de documento es requerido',
        }
    },
    USER_NEW_EDIT: {
        VALIDATIONS: {
            DOCUMENT_REQUIRED: '* El documento es requerido',
            DOCUMENT_TYPE_REQUIRED: '* El tipo de documento es requerido',
            FIRST_NAME_REQUIRED: '* El nombre es requerido',
            LAST_NAME_REQUIRED: '* El apellido es requerido',
            USERNAME_REQUIRED: '* El usuario es requerido',
            EMAIL_REQUIRED: '* El Correo Electrónico es requerido',
            EMAIL_NO_PATTERN: '* El Correo Electrónico es invalido',
        },
        ERROR: {
            USERNAME_EXIST: 'El Usuario ##, ya existe en el sistema, intente con uno diferente.',
            EMAIL_EXIST: 'El Correo Electrónico ##, ya existe en el sistema, intente con uno diferente.',
            USER_CREATE_GENERAL_ERROR: 'Ocurrio un Error al momento de crear el usuario. Revise la información ingresada e intentelo nuevamente',
            USER_UPDATE_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar el usuario. Revise la información ingresada e intentelo nuevamente',
            USER_UPDATE_STATUS_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar el estado del usuario. Revise la información ingresada e intentelo nuevamente',
            USER_ASSIGN_GENERAL_ERROR: 'Ocurrio un Error al momento de asignar el usuario a la empresa. Revise la información ingresada e intentelo nuevamente',
            USER_EXIST_IN_COMPANY_GENERAL_ERROR: 'El numero de documento ingresado, ya existe como usuario asociado a la empresa. Revise la información ingresada e intentelo nuevamente',
        }
    },
    MATERIAL_NEW_EDIT: {
        VALIDATIONS: {
            GENERIC_NAME_REQUIRED: '* El nombre generico es requerido',
            COMMERCIAL_NAME_REQUIRED: '* El nombre comercial es requerido',
            CONSECUTIVE_REQUIRED: '* El consecutivo es requerido',
            UNIQUE_CODE_REQUIRED: '* El código único es requerido',
            OBSERVATIONS_REQUIRED: '* Las Observaciones son requeridas',
            AMOUNT_REQUIRED: '* El monto es requerido',
            PROVIDER_REQUIRED: '* El proveedor es requerido',
            LOT_REQUIRED: '* El lote es requerido',
            GROSS_WEIGHT_REQUIRED: '* El peso bruto es requerido',
            NET_WEIGHT_REQUIRED: '* El peso neto es requerido',
            REGISTRATION_DATE_REQUIRED: '* La fecha de Ingreso es requerida',
            EXPIRATION_DATE_REQUIRED: '* La fecha de Expiración es requerida',
            NEW_ANALYSIS_DATE_REQUIRED: '* La fecha de Reanalisis es requerida',
            MATERIAL_TYPE_REQUIRED: '* El tipo de Material es requerido',
            UNIT_MEASURE_REQUIRED: '* La unidad de Medida es requerida',
            DESCRIPTION_REQUIRED: '* La Descripción es requerida',
            ASPECTO_REQUIRED: '* El Aspecto es requerido',
            COLOR_REQUIRED: '* El Color es requerido',
            OLOR_REQUIRED: '* El Olor es requerido',
            PH_REQUIRED: '* El pH es requerido',
            DENSIDAD_REQUIRED: '* La Densidad es requerida',
            VISCOSIDAD_REQUIRED: '* La Viscosidad es requerida',
            SOLUBILIDAD_REQUIRED: '* La Solubilidad es requerida',
            G_ESPECIFICA_REQUIRED: '* La G. Específica es requerida',
            INDICE_REFRACCION_REQUIRED: '* El Índice de Refracción es requerido',
            GRADO_ALCOHOLICO_REQUIRED: '* El Grado Alcohólico es requerido',
            FUNCIONALIDAD_REQUIRED: '* La Funcionalidad es requerida',
            PUNTO_FUSION_REQUIRED: '* El Punto Fusión es requerido',
            ANALISIS_MICRO_REQUIRED: '* El Análisis Micro es requerido',
        },
        ERROR: {
            MATERIAL_MP_CREATE_GENERAL_ERROR: 'Ocurrio un Error al momento de crear la Materia Prima. Revise la información ingresada e intentelo nuevamente',
            MATERIAL_MP_UPDATE_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar la Materia Prima. Revise la información ingresada e intentelo nuevamente',
            MATERIAL_MP_UPDATE_STATUS_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar el estado de la Materia Prima. Revise la información ingresada e intentelo nuevamente',
            MATERIAL_MEE_CREATE_GENERAL_ERROR: 'Ocurrio un Error al momento de crear el Material de Envase y/o Empaque. Revise la información ingresada e intentelo nuevamente',
            MATERIAL_MEE_UPDATE_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar el Material de Envase y/o Empaque. Revise la información ingresada e intentelo nuevamente',
            MATERIAL_MEE_UPDATE_STATUS_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar el estado del Material de Envase y/o Empaque. Revise la información ingresada e intentelo nuevamente',
            MATERIAL_UPDATE_STATUS_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar el estado del Material. Revise la información ingresada e intentelo nuevamente',
        }
    },
    PROVIDER_NEW_EDIT: {
        VALIDATIONS: {
            PROVIDER_NAME_REQUIRED: '* El nombre es requerido',
            NIT_REQUIRED: '* El NIT es requerido',
            ADDRESS_REQUIRED: '* La dirección es requerida',
            CIUDAD_REQUIRED: '* La ciudad es requerida',
            PHONENUMBER_REQUIRED: '* El nunmero de telefono es requerido',
            EMAIL_REQUIRED: '* El email es requerido',
            CONTACT_REQUIRED: '* El contacto es requerido',
            DESCRIPTION_REQUIRED: '* La descripción es requerida',
            DEPARTMENT_REQUIRED: '* El departamento es requerido',
            CITY_REQUIRED: '* El municipio es requerido',
            EMAIL_NO_PATTERN: '* El Correo Electrónico es invalido',
        },
        ERROR: {
            PROVIDER_CREATE_GENERAL_ERROR: 'Ocurrio un Error al momento de crear el Proveedor. Revise la información ingresada e intentelo nuevamente',
            PROVIDER_UPDATE_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar el Proveedor. Revise la información ingresada e intentelo nuevamente',
            PROVIDER_UPDATE_STATUS_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar el estado del Proveedor. Revise la información ingresada e intentelo nuevamente',
        }
    },

    ORDEN_COMPRA_CONSTANTS: {
        VALIDATIONS: {
            ORDEN_COMPRA_REQUIRED: '* El número de Orden de Compra es requerido',
            DESCRIPTION_REQUIRED: '* La descripción es requerida',
            PROVIDER_REQUIRED: '* El proveedor es requerido',
            FECHA_PEDIDO_REQUIRED: '* La fecha del pedido es requerida',
        },
        ERROR: {
            ORDEN_COMPRA_CREATE_GENERAL_ERROR: 'Ocurrio un Error al momento de crear la Orden de Compra. Revise la información ingresada e intentelo nuevamente',
            ORDEN_COMPRA_REQUEST_ORDER_ERROR: 'Ocurrio un Error al momento de Solicitar el Pedido. Revise la información ingresada e intentelo nuevamente',
            ORDEN_COMPRA_ACCEPT_ORDER_ERROR: 'Ocurrio un Error al momento de Aceptar el Pedido. Revise la información ingresada e intentelo nuevamente',
            ORDEN_COMPRA_FINISH_ORDER_ERROR: 'Ocurrio un Error al momento de Finalizar el Pedido. Revise la información ingresada e intentelo nuevamente',
            ORDEN_COMPRA_DECLINE_ORDER_ERROR: 'Ocurrio un Error al momento de Rechazar el Pedido. Revise la información ingresada e intentelo nuevamente',
            ORDEN_COMPRA_EDIT_MATERIAL_ORDER_ERROR: 'Ocurrio un Error al momento de Actualizar el material asociado al Pedido. Revise la información ingresada e intentelo nuevamente',
            SEND_QUALITY_ORDER_ERROR: 'Ocurrio un Error al momento de Enviar a Calidad la Orden de Compra. Revise la información ingresada e intentelo nuevamente',
        }
    },
    MATERIAL_ORDEN_COMPRA_CONSTANTS: {
        VALIDATIONS: {
            MATERIAL_OC_REQUIRED: '* El material es requerido',
            AMOUNT_REQUIRED: '* La cantidad es requerida',
            AMOUNT_RECEIVED_REQUIRED: '* La cantidad recibida es requerida',
            FECHA_ENTREGA_REQUIRED: '* La fecha de entrega es requerida',
            LOTE_PROVIDER_REQUIRED: '* El lote del proveedor es requerido',
            GROSSS_WEIGHT_REQUIRED: '* El peso bruto es requerido',
            NET_WEIGHT_REQUIRED: '* El peso neto es requerido',
            UNIT_VALUE_REQUIRED: '* El valor unitario es requerido',
            EXPIRATION_DATE_REQUIRED: '* La fecha de vencimiento es requerida',
            AMOUNT_RECEIVED_ZERO: '* La cantidad recibida debe ser mayor a 0',
        },
        ERROR: {
            MATERIAL_ORDEN_COMPRA_CREATE_GENERAL_ERROR: 'Ocurrio un Error al momento de crear la Orden de Compra. Revise la información ingresada e intentelo nuevamente',
            MATERIAL_ORDEN_COMPRA_EDIT_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar la informacion del material. Revise la información ingresada e intentelo nuevamente',
        }
    },
    QUALITY_CONSTANTS: {
        VALIDATIONS: {
            QUALITY_NOTES_REQUIRED: '* Las notas de Calidad son requeridas',
            QUALITY_AMOUNT_REQUIRED: '* La cantidad es requerida',
        },
        ERROR: {
            APPROVE_MATERIAL_ORDER_ERROR: 'Ocurrio un Error al momento de aprobar el material en Calidad. Revise la información ingresada e intentelo nuevamente',
            DECLINE_MATERIAL_ORDER_ERROR: 'Ocurrio un Error al momento de rechazar el material en Calidad. Revise la información ingresada e intentelo nuevamente',
            PARTIAL_APPROVE_MATERIAL_ORDER_ERROR: 'Ocurrio un Error al momento de aprobar parcialmente el material en Calidad. Revise la información ingresada e intentelo nuevamente',
        }
    },
    INSPECTION_CONSTANTS: {
        VALIDATIONS: {
            INSPECTION_MP_CANTIDAD_MUESTREADA_REQUIRED: '* La Cantidad Muestreada es requerida',
            INSPECTION_MP_FECHA_ANALISIS_REQUIRED: '* La Fecha de Análisis es requerida',
            INSPECTION_MP_FECHA_FABRICACION_REQUIRED: '* La Fecha de Fabricación es requerida',

            INSPECTION_MP_ASPECTO_REQUIRED: '* El Aspecto es requerido',
            INSPECTION_MP_ASPECTO_DETALLE_REQUIRED: '* El Resultado del Aspecto es requerido',
            INSPECTION_MP_COLOR_REQUIRED: '* El Color es requerido',
            INSPECTION_MP_COLOR_DETALLE_REQUIRED: '* El Resultado del Color es requerido',
            INSPECTION_MP_OLOR_REQUIRED: '* El Olor es requerido',
            INSPECTION_MP_OLOR_DETALLE_REQUIRED: '* El Resultado del Olor es requerido',
            INSPECTION_MP_PH_REQUIRED: '* El pH es requerido',
            INSPECTION_MP_PH_DETALLE_REQUIRED: '* El Resultado del pH es requerido',
            INSPECTION_MP_DENSIDAD_REQUIRED: '* La Densidad es requerida',
            INSPECTION_MP_DENSIDAD_DETALLE_REQUIRED: '* El Resultado de la Densidad es requerido',
            INSPECTION_MP_VISCOSIDAD_REQUIRED: '* La Viscosidad es requerida',
            INSPECTION_MP_VISCOSIDAD_DETALLE_REQUIRED: '* El Resultado de la Viscosidad es requerido',
            INSPECTION_MP_SOLUBILIDAD_REQUIRED: '* La Solubilidad es requerida',
            INSPECTION_MP_SOLUBILIDAD_DETALLE_REQUIRED: '* El Resultado de la Solubilidad es requerido',
            INSPECTION_MP_G_ESPECIFICA_REQUIRED: '* La G. Específica es requerida',
            INSPECTION_MP_G_ESPECIFICA_DETALLE_REQUIRED: '* El Resultado de la G. Específica es requerido',
            INSPECTION_MP_INDICE_REQUIRED: '* El Indice de Refacción es requerido',
            INSPECTION_MP_INDICE_DETALLE_REQUIRED: '* El Resultado del Indice de Refacción es requerido',
            INSPECTION_MP_GRADO_ALCOHOLICO_REQUIRED: '* El Grado Alcohólico es requerido',
            INSPECTION_MP_GRADO_ALCOHOLICO_DETALLE_REQUIRED: '* El Resultado del Grado Alcohólico es requerido',
            INSPECTION_MP_FUNCIONALIDAD_REQUIRED: '* La Funcionalidad es requerida',
            INSPECTION_MP_FUNCIONALIDAD_DETALLE_REQUIRED: '* El Resultado de la Funcionalidad es requerido',
            INSPECTION_MP_PUNTO_FUSION_REQUIRED: '* El Punto Fusión es requerido',
            INSPECTION_MP_PUNTO_FUSION_DETALLE_REQUIRED: '* El Resultado del Punto Fusión es requerido',
            INSPECTION_MP_INDICE_ACIDEZ_REQUIRED: '* El Índice de Acidez es requerido',
            INSPECTION_MP_INDICE_ACIDEZ_DETALLE_REQUIRED: '* El Resultado del Índice de Acidez es requerido',
        },
        ERROR: {
            INSPECCION_MP_NEW_GENERAL_ERROR: 'Ocurrio un Error al momento de registrar la inspección. Revise la información ingresada e intentelo nuevamente',
        }
    },
    PRODUCTION_PRODUCT_CONSTANTS: {
        VALIDATIONS: {
            NOMBRE_PRODUCTO_REQUIRED: '* El Nombre del Producto es requerido',
            FORMA_COSMETICA_REQUIRED: '* La Forma Cosmetica es requerida',
            REGISTRO_SANITARIO_REQUIRED: '* El Registro Sanitario es requerido',
            INICIALES_REQUIRED: '* Las Iniciales del Lote son requeridas',
            CANTIDAD_REQUIRED: '* La Cantidad es requerida',
            DENSIDAD_REQUIRED: '* La Densidad es requerida',
            COLOR_REQUIRED: '* El Color es requerido',
            OLOR_REQUIRED: '* El Olor es requerido',
            ASPECTO_REQUIRED: '* El Aspecto es requerido',
        },
        ERROR: {
            PRODUCTION_PRODUCT_NEW_GENERAL_ERROR: 'Ocurrio un Error al momento de registrar la ficha del producto. Revise la información ingresada e intentelo nuevamente',
        }
    },
    PRODUCTION_ORDER_CONSTANTS: {
        VALIDATIONS: {
            PRODUCT_REQUIRED: '* El producto es requerido',
            OBSERVACIONES_REQUIRED: '* Las Observaciones son requeridas',
            TAMANIOlOTE_REQUIRED: '* El Tamaño del Lote es requerido',
            LOTE_REQUIRED: '* El Tamaño del Lote es requerido',
        },
        ERROR: {
            PRODUCTION_ORDER_NEW_GENERAL_ERROR: 'Ocurrio un Error al momento de registrar la orden de Producción. Revise la información ingresada e intentelo nuevamente',
        }
    },
    EQUIPOS_CONSTANTS: {
      VALIDATIONS: {
        CODIGO_REQUIRED: '* El codigo es requerido',
        NOMBRE_REQUIRED: '* El nombre es requerido',
        UBICACION_REQUIRED: '* La ubicacion es requerida',
        RANGO_REQUIRED: '* El rango para efectuar manteniento es requerido',

        EQUIPO_REQUIRED: '* El equipo es requerido',
        TIPOS_MANTENIMIENTO_REQUIRED: '* El tipo de mantenimiento es requerido',
        OBSERVACION_REQUIRED: '* La observacion es requerida',
      },
      ERROR: {
        EQUIPO_NEW_ERROR: 'Ocurrio un Error al momento de registrar el equipo. Revise la información ingresada e intentelo nuevamente',
        MANTENIMIENTO_EQUIPO_NEW_ERROR: 'Ocurrio un Error al momento de registrar el mantenimiento del equipo. Revise la información ingresada e intentelo nuevamente',
      }
    },
    INSTRUCTIVO_CONSTANTS: {
      VALIDATIONS: {
        EQUIPO_EXIST: 'El equipo ya fue relacionado',
      }
    },
    PASOS_INSTRUCTIVO_CONSTANTS: {
      VALIDATIONS: {
        DESCRIPCION_REQUIRED: '* La descripcion es requerida',
        PRECAUCION_EXIST:'* La precaucion ya fue relacionada',
        TIPO_PASO_REQUIRED: '* El tipo de paso es requerido',
        CANTIDAD_REQUIRED: '* La cantidad es requerida',
        POSICION_REQUIRED:'* La posicion es requerida',
      }
    },
    PROGRAMACION_CONSTANTS:{
      VALIDATIONS:{
        FECHA_INICIAL_REQUIRED: '* La fecha inicial es requerida',
        FECHA_FINAL_REQUIRED: '* La fecha inicial es requerida',
        TITULO_REQUIRED: '* El titulo es requerido',

        ORDEN_REQUIRED: '* La orden de produccion es requerida',
        FECHA_CRONOGRAMA_REQUIRED: '* La fecha del cronograma es requerida',
        TIPO_OPERACION_REQUIRED: '* El tipo de operacion es requerida',
        OBSERVACION_REQUIRED: '* La observacion es requerida',

        ANIO_REQUIRED: '* El año es requerido',
        MES_REQUIRED: '* El mes es requerido',
        PRODUCTO_REQUIRED: '* El producto es requerido',
        PRESENTACION_REQUIRED: '* La presentación es requerida',
        CONSUMO_PROMEDIO_REQUIRED: '* El consumo promedio es requerido',
        CONSUMO_PASADO_REQUIRED: '* El consumo pasado es requerido',
        EXISTENCIAS_BODEGA_REQUIRED: '* Existencias por bodega es requerido',
        EXISTENCIAS_PRODUCCION_REQUIRED: '* Existencias produccion es requerido',
      },
      ERROR:{
        CRONOGRAMA_CREATE_GENERAL_ERROR: 'Ocurrio un Error al momento de registrar el cronograma. Revise la información ingresada e intentelo nuevamente',
        PLANEACION_CREATE_GENERAL_ERROR: 'Ocurrio un Error al momento de agregar el producto. Revise la información ingresada e intentelo nuevamente.',
        PLANEACION_UPDATE_GENERAL_ERROR: 'Ocurrio un Error al momento de actualizar el producto. Revise la información ingresada e intentelo nuevamente'
      }

    }
}
