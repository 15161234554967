export const MODAL_CONSTANTS = {
    CONFIRMATION: {
        TITLE: 'Confirmación',
        BUTTON_YES: 'Sí',
        BUTTON_NO: 'No'
    },
    INFORMATION: {
        TITLE: 'Información',
        BUTTON_YES: 'Sí',
    },
    CUSTOM: {
        USER_EXIST_TITLE: 'Usuario Existe',
        USER_EXIST_DESC: 'Actualmente existe un Usuario en el sistema, con el documento:',
        USER_EXIST_DESC_QUESTION: '¿Desea asignar el usuario para la Empresa ##?',
        BUTTON_YES: 'Sí',
        BUTTON_NO: 'No',
        BUTTON_CANCEL: 'Cancelar',
        BUTTON_DECLINE_ORDER: 'Rechazar Pedido',
        BUTTON_EDIT_MATERIAL: 'Editar Material',
        BUTTON_APPROVE_MATERIAL: 'Aprobar Material',
        BUTTON_APPROVE_DEVIATION_MATERIAL: 'Aprobar Material con Desviado',
        BUTTON_DECLINE_MATERIAL: 'Rechazar Material',
        BUTTON_PARTIAL_APPROVE_MATERIAL: 'Aprobado Parcial Material',
        MSG_CANCELED_PROCESS: 'CANCELED_PROCESS',
        BUTTON_GENERATE_CERTIFICATE: 'Generar Certificado',
    },
    NOTIFICATION: {
        TOOLTIP_CLOSE: 'Cerrar',
    },
} 